module.exports = {
    "@metadata": {
        "authors": [
          "Nikolas Dahn"
        ],
        "last-updated": "2021-07-17",
        "locale": "de",
        "message-documentation": "qqq"
    },

    "seo-description": "Rocksi ist ein kostenloser Roboter-Simulator, mit dem du einen virtuellen 3D-Roboter in deinem Browser programmieren kannst!",
    "seo-header": "Programmiere deinen Roboter im Browser!",
    "seo-text": "Rocksi ist ein Simulator, mit dem du einen virtuellen 3D-Roboter ähnlich wie in Scratch oder Lego Mindstorms programmieren kannst. Rocksi läuft auf jedem modernen Browser (Firefox, Chrome, Safari...) und Gerät (iPhone, iPad, IOS, Android, Linux, Windows, ...) - ohne Download oder Installation! Rocksi ist außerdem völlig kostenlos und erfordert keine Registrierung.<br>Rocksi wurde geschrieben, um mehr Menschen Zugang zu Robotern zu geben, insbesondere für den Bildungsbereich. Egal ob Schulklassen, Vorlesungen, Workshops oder Messen, Rocksi bringt Menschen und Roboter näher zusammen! Bisher wurden Roboter von Franka Emika und Niryo eingebaut, weitere werden je nach Interesse der Hersteller folgen.<br>Rocksi wurde für die deutsche <a href=\"https://robokind.de\" target=\"_blank\">Robokind Stiftung</a> geschrieben, welche Schulungen und Workshops zum Thema Robotik entwickelt. Die kostenlosen Onlinekurse - z.B. der Roboterführerschein - können unter <a href=\"https://robotikschulungen.de\" target=\"_blank\">www.robotikschulungen.de</a> gefunden werden.",

    "robot-loading": "Lade Roboter...",
    "warn-unsaved": "Der aktuelle Zustand wird nicht gespeichert. Fortfahren?",

    "tutorial-0-heading": "Willkommen zu Rocksi!",
    "tutorial-0-intro": "Roboter sind teuer, weshalb es für die meisten Menschen sehr schwer ist, sie wirklich kennenzulernen. Doch Menschen lieben es, etwas anzufassen! Genau dafür wurde Rocksi geschrieben - um Menschen mit Robotern in Berührung zu bringen. Egal ob Lehrer, Schüler, Fachkraft oder einfach nur technik-affin, Rocksi hilft, die Grundlagen der Robotik zu verstehen und mit anderen zu teilen.",
    "tutorial-0-start": "Mit dem Pfeil rechts kommst du zum ersten Tutorial.<br><i>Viel Spaß!</i>",
    "tutorial-0-social": "Updates & Feedback",
    "tutorial-1-heading": "Behalte den Überblick",
    "tutorial-1": "Über das Menü links kannst du schnell zwischen den Ansichten wechseln.",
    "tutorial-2-heading": "Voll im Bilde",
    "tutorial-2-desktop": "Mit Links- und Rechtsklick drehst du dir die Welt, wie sie dir gefällt.",
    "tutorial-2-mobile": "Mit den gewohnten Touch-Gesten findest du jedes Detail.",
    "tutorial-3-heading": "Handzahm",
    "tutorial-3": "Den Roboter kannst du direkt in der 3D-Ansicht bewegen. Über das Menü kannst du auch gezielt einzelne Gelenke steuern.",
    "tutorial-4-heading": "Das Leben ist ein Puzzle-Spiel",
    "tutorial-4": "Mithilfe der Puzzleteile kannst du dir schnell ein Programm für den Roboter zusammenzubauen.",
    "tutorial-5-heading": "Haltung Wahren",
    "tutorial-5": "Nutze die Pfeile um Posen des Roboters zwischen Blöcken und Roboter zu übertragen.",
    "tutorial-6-heading": "Let it go",
    "tutorial-6": "Um ein Programm auszuführen klickst du in der 3D-Ansicht auf den Play-Button.",
    "tutorial-7-heading": "Aus dem Kontext gerissen",
    "tutorial-7": "Das Kontext-Menü gibt dir weitere Optionen. Außerdem kannst du so deine Programme speichern und laden.",
    "tutorial-rf-heading": "Roboterführerschein",
    "tutorial-rf": "Du möchtest noch mehr wissen oder dir war etwas unklar? Dann mach doch unseren kostenlosen {{link:Roboterführerschein|https://www.robotikschulungen.de}}",

    "license-note": "Rocksi wird unter der <a href=\"https://tldrlegal.com/license/mit-license\" target=\"_blank\">MIT Lizenz</a> veröffentlicht<br>",

    "imprint": "Impressum",
    "privacy": "Datenschutz",
    "software": "Software",
    "imprint-content": "i18n/imprint_de.html",
    "privacy-content": "i18n/privacy_de.html",
    "software-content": "i18n/software_de.html",

    "gui-robot": "Rocksi",
    "gui-language": "Sprache",
    "gui-gripper": "Greifer",
    "gui-gripper-open": "Öffnen",
    "gui-gripper-close": "Schließen",
    "gui-advanced-gripping": "Reales Greifen",
    "gui-joints": "Gelenkwinkel",
    "gui-ik-joints": "IK Gelenke",
    "gui-reset-robot": "Roboter zurücksetzen",
    "gui-reset-view": "Ansicht zurücksetzen",
    "gui-close": "Schließen",
    "gui-confirm-switch-robot": "Möchtest du den $1 Roboter laden? Dein aktuelles Programm geht dabei verloren!",
    "gui-confirm-switch-language": "Möchtest du die Sprache wechseln? Dein aktuelles Programm geht dabei verloren!",
    "gui-robot-info": "Info",

    "franka-info": "Der Roboter von Franka Emika zeichnet sich durch 7 Gelenkfreiheitsgrade und einen hohen Grad von Feingefühl aus. Dadurch kann sich Franka ähnlich wie der menschliche Arm bewegen und auch auf leichte Berührungen reagieren. <p><a href=\"https://franka.de/\" target=\"_blank\">www.franka.de</a></p>",
    "niryo-info": "Niryo stellt kleine Desktoproboter mit 6 Gelenken her, die vor allem für den Bildungsbereich gedacht sind. Mit einem 3D-Drucker können technisch Begeisterte den Roboter sogar selbst bauen! <p><a href=\"https://niryo.com/\" target=\"_blank\">www.niryo.com</a></p>",
    "sawyer-info": "Der Sawyer ist der Nachfolger des bekannten zweiarmigen Baxter Roboters und ist äußerst beliebt in der Forschung. Die Anordnung der Gelenke führt zu einigen sehr ungewöhnlichen Bewegungen! <p><a href=\"https://www.rethinkrobotics.com/de/sawyer/\" target=\"_blank\">www.rethinkrobotics.com/de/sawyer/</a></p>"
};