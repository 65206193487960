export const BlocklyCustomEN = {
    UNDO: "\u21B6 Undo",
    REDO: "\u21B7 Redo",
    CLEAN_UP: "\u2833 Clean Up",
    DELETE_BLOCK: "\u2298 Delete",
    DELETE_X_BLOCKS: "\u2298 Delete %1 blocks",
    LOAD: "\u{1F845} Load program...",
    SAVE: "\u{1F847} Save program...",
    CODE_EXPORT: "\u2756 Export code...",
    INLINE_INPUTS: "\u25EB Arrange horizontal",
    EXTERNAL_INPUTS: "\u229F Arrange vertical",
    DUPLICATE_BLOCK: "\uA698 Clone",
    ADD_COMMENT: "\u270E Comment",
    
    REMOVE_COMMENT: "\u{1F7A9} Delete comment",
    COLLAPSE_BLOCK: "\u25F2 Reduce",
    EXPAND_BLOCK: "\u29C9 Expand",
    DISABLE_BLOCK: "\u{1F5F2} Deactivate",
    ENABLE_BLOCK: "\u{1F5F2} Activate",

    EXEC_ERROR: "Program exception",
    EXEC_SUCCESS: "Program finished",
    EMPTY_PROGRAM: "Empty program",
    SEE_CONSOLE: "Please find additional details in the browser console.",

    ROCKSI_CATEGORY_MOVE: "Moving",
    ROCKSI_CATEGORY_OBJECTS: "Objects",
    ROCKSI_CATEGORY_LOGIC: "Logic",
    ROCKSI_CATEGORY_EXTRAS: "Extras",
    ROCKSI_CATEGORY_EXAMPLES: "Examples",

    ROCKSI_BLOCK_MOVE: "Move %1",
    ROCKSI_BLOCK_MOVE_TOOLTIP: "Connect to a joint or task space pose to make the robot move there",
    ROCKSI_BLOCK_DEFAULTPOSE: "Initial pose",
    ROCKSI_BLOCK_DEFAULTPOSE_TOOLTIP: "Initial pose of the robot",
    ROCKSI_BLOCK_JOINTSPACEPOSE_TOOLTIP: "A joint space pose, i.e. one angle per joint starting from the base",
    ROCKSI_BLOCK_TASKSPACEPOSE_TOOLTIP: "A task space pose, i.e. a position and orientation in space",
    ROCKSI_BLOCK_JOINTABSOLUTE: "Joint angle (absolute) %1 %2",
    ROCKSI_BLOCK_JOINTABSOLUTE_TOOLTIP: "Move a single joint to a set angle",
    ROCKSI_BLOCK_JOINTRELATIVE: "Joint angle (relative) %1 %2",
    ROCKSI_BLOCK_JOINTRELATIVE_TOOLTIP: "Move a single joint by a given amount",

    ROCKSI_BLOCK_GRIPPEROPEN: "Gripper open",
    ROCKSI_BLOCK_GRIPPEROPEN_TOOLTIP: "Opens the gripper to its full width",
    ROCKSI_BLOCK_GRIPPERCLOSE: "Gripper close",
    ROCKSI_BLOCK_GRIPPERCLOSE_TOOLTIP: "Completely closes the gripper",
    ROCKSI_BLOCK_SIMOBJECT: "Place a %1", 
    ROCKSI_BLOCK_SIMOBJECT_CUBE: "Cube",
    ROCKSI_BLOCK_SIMOBJECT_SPHERE: "Sphere",
    ROCKSI_BLOCK_SIMOBJECT_ROCK: "Rock",
    ROCKSI_BLOCK_SIMOBJECT_SHAFT: "Shaft",
    ROCKSI_BLOCK_SIMOBJECT_CUSTOM: "Custom object...",
    ROCKSI_BLOCK_SIMOBJECT_COLOR: "with color %1",
    ROCKSI_BLOCK_SIMOBJECT_POSITION: "at position %1",
    ROCKSI_BLOCK_SIMOBJECT_SCALE: "scaled by %1",
    ROCKSI_BLOCK_SIMOBJECT_TOOLTIP: "Adds an object to the scene that can be grabbed by the robot",
    ROCKSI_BLOCK_PHYSICSDONE: "Physics simulation done?",
    ROCKSI_BLOCK_PHYSICSDONE_TOOLTIP: "Returns true once all objects stopped moving (not considering the robot)",
    ROCKSI_BLOCK_HOLDING: "Holding?",
    ROCKSI_BLOCK_HOLDING_TOOLTIP: "Returns true if the robot is holding an object",

    ROCKSI_BLOCK_COMMENT: "Comment %1",
    ROCKSI_BLOCK_COMMENT_TOOLTIP: "Comment, ignored during execution",
    ROCKSI_BLOCK_WAIT: "Wait %1 seconds",
    ROCKSI_BLOCK_WAIT_TOOLTIP: "Waits some time before executing the next block",
    ROCKSI_BLOCK_SETSPEED: "Set speed of %1 to %2 %%",
    ROCKSI_BLOCK_SETSPEED_MOVE: "Moving",
    ROCKSI_BLOCK_SETSPEED_GRIPPER: "Grasping",
    ROCKSI_BLOCK_SETSPEED_TOOLTIP: "Changes the speed at which the robot moves",
    ROCKSI_BLOCK_JOINTLOCK: "Lock %1",
    ROCKSI_BLOCK_JOINTLOCK_TOOLTIP: "Prevents a joint from moving during program execution",
    ROCKSI_BLOCK_JOINTUNLOCK: "Unlock %1",
    ROCKSI_BLOCK_JOINTUNLOCK_TOOLTIP: "Allows a joint to move again during program execution",

    ROCKSI_BLOCK_SCALE: "%1",
    ROCKSI_BLOCK_SCALE_TOOLTIP: "A factor",

    ROCKSI_EXAMPLE_PICK_N_PLACE: "Pick & Place: picking up and letting go of an object",
    ROCKSI_EXAMPLE_PICK_N_PLACE_COMMENT_1: "Jointspace poses will always be more reliable!"
}